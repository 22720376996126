import 'alpinejs';
import 'jquery-ui-sortable-npm';
import Swal from 'sweetalert2';
window.Swal=Swal;
window._ = require('lodash');
window.$ = window.jQuery = require('jquery');

import * as FilePond from "filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
window.FilePond=FilePond;
window.FilePondPluginImagePreview=FilePondPluginImagePreview;
window.FilePondPluginImageExifOrientation=FilePondPluginImageExifOrientation;
window.FilePondPluginImageValidateSize=FilePondPluginImageValidateSize;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo'

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
});
